import { APPOINTMENT_CHANNEL_ID, EVENT_MESSAGE, IGNORE_IFRAME, MOBILE_SCREEN } from '../../utils/constants';
import { isModalPopup } from '../../utils/variables';
import { params } from './movePages';
import disableScroll from 'disable-scroll';

export function assistantListeners() {
    /* Toggle assistant widget */
    document.querySelector('.assistant__container .assistant__button').onclick = toggleWidgetAssistant;

    /*Close Tooltip*/
    document.querySelector('.assistant__container .tooltip__text').onclick = closeTooltip;

    /* on click sub-button to close assistant when on mobile */
    document
        .querySelectorAll('.assistant__container .subCLose__btn')
        .forEach((i) => (i.onclick = toggleWidgetAssistant));

    /* Receive message finish load iframe */
    bindEvent(window, 'message', function (e) {
        if (e.data === EVENT_MESSAGE.FINISH_LOADING_IFRAME) {

            const assistantIframeTemp = document.querySelector(
                '.assistant__container .assistant__content--iframe'
            );
            const selector = document.querySelector('script[data-id="assistant"]');
            const book_appointment = selector.getAttribute('book_appointment');
            if (isModalPopup && book_appointment) {
                assistantIframeTemp.contentWindow.postMessage(
                    'navigate' + EVENT_MESSAGE.BOOK_APPOINTMENT,
                    '*'
                );
                setTimeout(function () {
                    hideLoadingWrapper();

                }, 300);
            } else {
                hideLoadingWrapper();
            }

            if (params(IGNORE_IFRAME)) {
                document.querySelector('.widgetContent__container .subCLose__btn').style.visibility = 'hidden';
            }
        }

        if (e.data === EVENT_MESSAGE.ENABLE_SCROLL) {
            disableScroll.off();
        }
        if (e.data === EVENT_MESSAGE.DISABLE_SCROLL && document.querySelector('.assistant__container').classList.contains('show')) {
            disableScroll.on();
        }
    });

    /* Check query params to open assistant automatically */
    if (params('is_open_assistant')) {
        toggleWidgetAssistant();
    }

    /** Close modal when press escape */
    window?.addEventListener('keydown', closeModalPressEscape);
}
export function toggleWidgetAssistant() {
    const assistantIframe = document.querySelector(
        '.assistant__container .assistant__content--iframe'
    );

    if (!document.querySelector('.assistant__container').classList.contains('show')) {
        const isOpen = (params('staff_id') && params('procedure_id')) || params(APPOINTMENT_CHANNEL_ID)
        if (isOpen) {
            if (assistantIframe) {
                assistantIframe.contentWindow.postMessage(
                    EVENT_MESSAGE.OPEN_BOOK_APPOINTMENT_FLOW,
                    '*'
                );
            }
        }
        openAssistant();
    } else {
        closeAssistant();
    }

    if (assistantIframe) {
        assistantIframe.contentWindow.postMessage(
            EVENT_MESSAGE.TOGGLE_WIDGET,
            '*'
        );

        if (MOBILE_SCREEN) {
            assistantIframe.contentWindow.postMessage(
                EVENT_MESSAGE.ENABLE_SCROLL,
                '*'
            );
        }
    }
}

function openAssistant() {
    if (MOBILE_SCREEN) {
        disableScroll.on();
    }

    const selector = document.querySelector('script[data-id="assistant"]');
    const book_appointment = selector.getAttribute('book_appointment');
    if (isModalPopup && book_appointment) {
        document.querySelector('.assistant__container').classList.add('modal');
        let overlay = document?.querySelector('.overlay__popup');
        if (overlay) {
            overlay.classList.remove('hide');
        }
    }

    document.querySelector('.assistant__container').classList.add('show');
}

export function closeAssistant() {
    if (MOBILE_SCREEN) {
        disableScroll.off();
    }
    document.querySelector('.assistant__container').classList.remove('show');
    document
        .querySelector('.assistant__container .assistant__content--iframe')
        .contentWindow.postMessage(EVENT_MESSAGE.REFRESH, '*');
    window.parent.postMessage(EVENT_MESSAGE.ENABLE_SCROLL, '*');
    const selector = document.querySelector('script[data-id="assistant"]');
    const book_appointment = selector.getAttribute('book_appointment');
    if (isModalPopup && book_appointment) {
        document.querySelector('.assistant__container').classList.add('modal');
        let overlay = document?.querySelector('.overlay__popup');
        if (overlay) {
            overlay.classList.add('hide');
        }
    }
}

function hideLoadingWrapper() {
    document.querySelector('.assistant__container .loading__wrapper').classList.add('hidden');
}

export function closeTooltip() {
    document.querySelector('.tooltip__text').classList.add('hidden');
}

export function showCloseTooltip() {
    document.querySelector('.close-tooltip-btn').classList.add('show');
}

export function bindEvent(element, eventName, eventHandler) {
    if (element.addEventListener) {
        element.addEventListener(eventName, eventHandler, false);
    } else if (element.attachEvent) {
        element.attachEvent('on' + eventName, eventHandler);
    }
}

function closeModalPressEscape(e) {
    if (e.key === 'Escape' && isModalPopup) {
        closeAssistant();
    }
}
