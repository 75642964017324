import { CLOSE, SCREEN } from './constants';

export var access_token = '';

export var isOpenWidget = false;
export var currentScreen = SCREEN.DASHBOARD;
export var preScreen = null;
export var procedures = [];
export var procedureId = null;
export var countriesConsult = CLOSE;
export var countriesAskQs = CLOSE;
export var countriesPhone = CLOSE;
export var selectedCountry = 'US';
export var selectedCodeNumber = '1';
export var categories = [];
export var redirectUrl = '';
export var webAssistantOptions = [];
export var selectedProcedures = [];
export var selectedAddons = [];
export var createdLoader = false;
export var providers = [];
export var originProviders = [];
export var originProcedures = [];
export var providerId = '';
export var faqQuestions = [];
export var primaryColor = '';
export var tooltip = '';
export var picture = '';
export var locations = [];
export var locationId = '';
export var scheduling = 0;
export var selectedLocation = '';
export var emailOptin = false;
export var phoneToCall = '';
export var form = {};
export var optionNames = {};
export var optionIcons = {};
export var widgetOptions = [];
export var formId = '';
export var staffId = 0;
export var appUrl = '';
export var patientType = '';
export var procedureSelectionType = '';
export var startTime = 0;
export var endTime = 0;
export var providerServiceSummary = {};
export var selectedTime = {};
export var leadInfo = null;
export var sourceParam = '';
export var visitorId = '';
export var selectedInterest = '';
export var interests = [];
export var fullName = '';
export var email = '';
export var emailCheck = false;
export var phone = '';
export var estimateInfo = {};
export var isInInstantQuote = false;
export var surgicalOption = null;
export var isBookAppointment = false;
export var limitRightCarousel = 0;
export var pagesDom = [];
export var appointmentDateRange = {};
export var currentDateInUse = 0;
export var carouselCountLeft = 0;
export var isClickCheckBox = false;
export var isOTPTimeOut = true;
export var selectedAppointmentUnixTime = '';
export var appointmentSummary = {};
export var eventPayload = {};
export var eventId = null;
export var appointmentDuration = 0;
export var leadTextId = null;
export var customField = {};
export var customFields = [];
export var fileUploadChannel = [];
export var financing = [];
export var nameScreenDynamic = '';
export var firstLoadCustomFields = false;
export var listDataQuestion = {};
export var listNamePageQuestion = [];
export var firstQuestion = false;
export var selectedDateElement = null;
export var selectedDatetime = null;
export var selectedGender = null;
export var eventRoom = null;
export var consultationNonSurgicalPath = null;
export var businessName = '';
export var requestsGetSlotsTime = [];
export var nextRequestsGetSlotsTime = [];
export var showMaleFemalePage = false;
export var progressQuestion = {};
export var staffProcedures = null;
export var dataSlot = {};
export var hidePricing = false;
export var showBodyAreaSelection = false;
export var bodyAreaSelected = [];
export var isModalPopup = false;
export var hasLead = false;
export var projectId = null;
export var patientSelected = null;
export var procedureIdSelected = null;
export var isDesktopVer = false;
export var appointmentChannelId = null;
export var proceduresOfAppointmentChannel = null;
export var showSubscribedToOffersOption = null;
export var selectedStaffAppointmentChannel = null;
export var isCheckedNote = false;
export var isDisableSearchService = false;
export var selectedLocations = [];

export function setVisitorId(val) {
    visitorId = val;
}

export function setTooltip(val) {
    tooltip = val;
}

export function setPicture(val) {
    picture = val;
}

export function setSourceParam(val) {
    sourceParam = val;
}

export function setLeadInfo(val) {
    leadInfo = val;
}

export function setSelectedTime(val) {
    selectedTime = val;
}

export function setProviderServiceSummary(val) {
    providerServiceSummary = val;
}

export function setTime(start, end) {
    startTime = start;
    endTime = end;
}

export function setProcedureSelectionType(val) {
    procedureSelectionType = val;
}

export function setPatientType(val) {
    patientType = val;
}

export function setAppUrl(val) {
    appUrl = val;
}

export function setFormId(val) {
    formId = val;
}

export function setStaffId(val) {
    staffId = val
}

export function setIsOpenWidget(val) {
    isOpenWidget = val;
}

export function setCurrentScreen(val) {
    currentScreen = val;
}

export function setPreScreen(val) {
    preScreen = val;
}

export function setProcedures(val) {
    procedures = val;
}

export function setRedirectUrl(val) {
    redirectUrl = val;
}

export function setCategories(val) {
    categories = val;
}

export function setWebAssistantOptions(val) {
    webAssistantOptions = val;
}

export function setSelectedProcedures(val) {
    selectedProcedures = val;
}

export function setSelectedAddons(val) {
    selectedAddons = val;
}

export function setCountriesConsult(val) {
    countriesConsult = val;
}

export function setCountriesAskQs(val) {
    countriesAskQs = val;
}

export function setCountriesPhone(val) {
    countriesPhone = val;
}

export function setSelectedCountry(val) {
    selectedCountry = val;
}

export function setSelectedCodeNumber(val) {
    selectedCodeNumber = val;
}

export function setProcedureId(val) {
    procedureId = val;
}

export function setCreatedLoader(val) {
    createdLoader = val;
}

export function setProviders(val) {
    providers = val;
}

export function setOriginProviders(val) {
    originProviders = val;
}

export function setOriginProcedures(val) {
    originProcedures = val;
}

export function setProviderId(val) {
    providerId = val;
}

export function setQuestions(val) {
    faqQuestions = val;
}

export function setPrimaryColor(val) {
    primaryColor = val;
}

export function setLocations(val) {
    locations = val;
}

export function setInterests(val) {
    interests = val;
}

export function setLocationId(val) {
    locationId = val;
}

export function setScheduling(val) {
    scheduling = val;
}

export function setSelectedLocation(val) {
    selectedLocation = val;
}

export function setSelectedInterest(val) {
    selectedInterest = val;
}

export function setEmailOptin(val) {
    emailOptin = val;
}

export function setPhoneToCall(val) {
    phoneToCall = val;
}

export function setForm(val) {
    form = val;
}

export function setOptionNames(key, value) {
    optionNames[key] = value;
}

export function setOptionIcons(key, value) {
    optionIcons[key] = value;
}

export function setWidgetOptions(val) {
    widgetOptions = val;
}

export function setFullName(val) {
    fullName = val;
}

export function setEmail(val) {
    email = val;
}

export function setEmailCheck(val) {
    emailCheck = val;
}

export function setPhone(val) {
    phone = val;
}

export function setEstimateInfo(val) {
    estimateInfo = val;
}

export function setIsInstantQuote(val) {
    isInInstantQuote = val;
}

export function setSurgicalOption(val) {
    surgicalOption = val;
}

export function setIsBookAppointment(val) {
    isBookAppointment = val;
}

export function setLimitRightCarousel(val) {
    limitRightCarousel = val;
}

export function addPagesDom(val) {
    pagesDom.push(val);
}

export function removeLastPagesDom() {
    pagesDom.pop();
}

export function resetPagesDom() {
    let temp = pagesDom[0];
    pagesDom.forEach((element) => {
        element.style.zIndex = '1';
    });
    pagesDom = [];
    temp.style.zIndex = '2';
    addPagesDom(temp);
}

export function setAppointmentDateRange(val) {
    appointmentDateRange = val;
}

export function setCurrentDateInUse(val) {
    currentDateInUse = val;
}

export function setCarouselCountLeft(val) {
    carouselCountLeft = val;
}

export function setIsClickCheckBox(val) {
    isClickCheckBox = val;
}

export function setIsOTPTimeOut(val) {
    isOTPTimeOut = val;
}

export function setSelectedAppointmentUnixTime(val) {
    selectedAppointmentUnixTime = val;
}

export function setAppointmentSummary(val) {
    appointmentSummary = val;
}

export function setEventPayload(val) {
    eventPayload = val;
}

export function setEventId(val) {
    eventId = val;
}
export function setAppointmentDuration(val) {
    appointmentDuration = val;
}

export function setLeadTextId(val) {
    leadTextId = val;
}

export function setCustomField(val) {
    customField = val;
}

export function setCustomFields(val) {
    customFields = val;
}

export function setFileUploadChannel(val) {
    fileUploadChannel = val;
}

export function setFinancing(val) {
    financing = val;
}

export function setNameScreenDynamic(val) {
    nameScreenDynamic = val;
}

export function setListDataQuestion(val) {
    listDataQuestion = val;
}

export function setListNamePageQuestion(val) {
    listNamePageQuestion = val;
}

export function setFirstQuestion(val) {
    firstQuestion = val;
}
export function setSelectedDateElement(val) {
    selectedDateElement = val;
}
export function setSelectedDatetime(val) {
    selectedDatetime = val;
}
export function setSelectedGender(val) {
    selectedGender = val;
}

export function setEventRoom(val) {
    eventRoom = val;
}

export function setConsultationNonSurgicalPath(val) {
    consultationNonSurgicalPath = val;
}

export function setBusinessName(val) {
    businessName = val;
}

export function setRequestsGetSlotsTime(val) {
    requestsGetSlotsTime = val;
}

export function setNextRequestsGetSlotsTime(val) {
    nextRequestsGetSlotsTime = val;
}

export function setShowMaleFemalePage(val) {
    showMaleFemalePage = val;
}

export function setProgressQuestion(val) {
    progressQuestion = val;
}

export function setStaffProcedures(val) {
    staffProcedures = val;
}

export function setDataSlot(val) {
    dataSlot = val;
}

export function setHidePricing(val) {
    hidePricing = val;
}

export function setShowBodyAreaSelection(val) {
    showBodyAreaSelection = val;
}

export function setBodyAreaSelected(val) {
    bodyAreaSelected = val;
}

export function setIsModalPopup(val) {
    isModalPopup = val;
}

export function setHasLead(val) {
    hasLead = val;
}

export function setProjectId(val) {
    projectId = val;
}

export function setPatientSelected(val) {
    patientSelected = val;
}

export function setAccessToken(val) {
    access_token = val;
}

export function setProcedureIdSelected(val) {
    procedureIdSelected = val;
}

export function setIsDesktopVer(val) {
    isDesktopVer = val;
}

export function setAppointmentChannelId(val) {
    appointmentChannelId = val;
}

export function setProceduresOfAppointmentChannel(val) {
    proceduresOfAppointmentChannel = val;
}

export function setShowSubscribedToOffersOption(val) {
    showSubscribedToOffersOption = val;
}

export function setSelectedStaffAppointmentChannel(val) {
    selectedStaffAppointmentChannel = val;
}

export function setIsCheckedNote(val) {
    isCheckedNote = val;
}

export function setIsDisableSearchService(val) {
    isDisableSearchService = val;
}

export function setSelectedLocations(val) {
    selectedLocations = val;
}